<script>
export default {
  name: "Navbar",
  data() {
    return {};
  },
  methods: {
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
    },
    change(yy) {
      if (yy == "EN") {
        this.$Message({
          message: "页面开发处理中，请稍候...",
          showClose: false,
          type: "info",
          offset: 210,
        });
        // this.$store.commit('changelanguage', 'EN');
      } else {
        this.$store.commit("changelanguage", "CN");
      }
    },
  },
};
</script>
<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark" id="navbar">
    <div class="container">
      <a class="navbar-brand logo text-uppercase" href="/"><img src="@/assets/images/logo.png" class="logoimg" /></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" @click="toggleMenu()">
        <i class="czlicon" style="font-size: 24px">&#xe680;</i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav navbar-center" id="mySidenav" v-scroll-spy-active="{ class: 'active' }">
          <li class="nav-item">
            <a v-scroll-to="{ el: '#home' }" class="nav-link" data-scroll-spy-id="home"
              href="javascript: void(0);">首页</a>
          </li>
          <li class="nav-item">
            <a v-scroll-to="{ el: '#about', offset: -5 }" class="nav-link" data-scroll-spy-id="about"
              href="javascript: void(0);">关于我们</a>
          </li>
          <li class="nav-item">
            <a v-scroll-to="{ el: '#services', offset: -5 }" class="nav-link" data-scroll-spy-id="services"
              href="javascript: void(0);">产品&服务</a>
          </li>
          <li class="nav-item">
            <a v-scroll-to="{ el: '#mall', offset: -5 }" class="nav-link" data-scroll-spy-id="mall"
              href="javascript: void(0);">纪元乐购</a>
          </li>
          <li class="nav-item">
            <a v-scroll-to="{ el: '#news' }" class="nav-link" data-scroll-spy-id="news"
              href="javascript: void(0);">新闻动态</a>
          </li>
          <li class="nav-item">
            <a v-scroll-to="{ el: '#help', offset: -5 }" class="nav-link" data-scroll-spy-id="help"
              href="javascript: void(0);">客户服务</a>
          </li>
          <li class="nav-item">
            <a v-scroll-to="{ el: '#contact' }" class="nav-link" data-scroll-spy-id="contact"
              href="javascript: void(0);">联系我们</a>
          </li>
        </ul>
        <div class="ml-auto">
          <ul class="nav navbar-nav navbar-right">
            <li>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="czlicon padding-right-8">&#xe711;</i>Language
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="change('CN')">中文</el-dropdown-item>
                  <el-dropdown-item divided @click.native="change('EN')">English</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #666666;
  font-size: 14px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
